<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1 max-w-1400">
        <img :src="importedImg1" :alt="altText" class="imgItem1" />
      </div>
    </div>
    <div class="imgBox2 min-w-1400">
      <div class="img2 max-w-1400">
        <!-- <h1>Cam kết bảo mật</h1> -->
        <!-- <div class="title-line"></div> -->
        <div class="textBox" style="white-space: pre-wrap">{{ text }}</div>
      </div>
    </div>

  </div>
</template>

<script>
import importedImg1 from "@/page/colfinactivasas/components/img/4_1.png";

export default {
  data() {
    return {
      altText: 'protocolTitle',
      importedImg1: importedImg1,
      text: `Esta política de privacidad (en adelante, la "Política") se aplica a esta aplicación (en adelante, la "Aplicación"). Le informa al usuario de la Aplicación y los servicios que proporciona ("usted") sobre la información que la Aplicación recopila y cómo la Aplicación utiliza y comparte esa información.


La Aplicación recopila información de los usuarios de la Aplicación que es identificable con un individuo específico ("Información de identificación personal") e información anónima que no puede asociarse con un individuo específico ("Información de identificación no personal").

La Aplicación recopila información de identificación personal cuando usted proporciona información de identificación personal a través del uso de la Aplicación, como al completar una o más etapas de una solicitud en línea ("Solicitud") a un prestamista que participa en la red de prestamistas de la Aplicación ("Solicitud" ) ofrece ofertas de préstamos. "Socio prestamista") o enviar preguntas a la solicitud por correo electrónico. Los tipos de información de identificación personal recopilada por esta aplicación incluyen: nombre, dirección física, número de teléfono, número de Seguro Social, dirección de correo electrónico, fecha de nacimiento, número de licencia de conducir y estado, información sobre propiedad de vivienda, estado de ciudadanía, información de empleo, servicio activo. información militar, el monto y la frecuencia de sus cheques de pago e información sobre su cuenta bancaria.

Los tipos de información de identificación no personal recopilada por la Aplicación (ya sea directamente mediante el uso de "cookies" o mediante el uso de sistemas de seguimiento de terceros) incluyen: la dirección IP del dispositivo que realiza la solicitud; su tipo de dispositivo e idioma ; tiempos de acceso; información sobre las visitas de los usuarios a la Aplicación Información sobre áreas de la aplicación; información sobre las páginas que los usuarios pueden elegir visitar en la aplicación. Las direcciones IP normalmente no están asociadas con su dispositivo personal, sino con el portal a través del cual accede a Internet. Las direcciones IP no están asociadas con información de identificación personal. Una "cookie" es un fragmento de información que una aplicación envía a su dispositivo cuando la ve para recordar quién es usted sin identificarlo personalmente.

La Aplicación puede compartir esta información de identificación no personal con sus proveedores y otros terceros con el fin de realizar estadísticas de la Aplicación y análisis de tendencias. Esta información no está vinculada a ninguna información de identificación personal.

La Aplicación puede permitir que terceros, incluidos socios publicitarios y prestamistas personales, muestren anuncios en la Aplicación. Estas empresas pueden utilizar tecnologías de seguimiento, incluidas cookies, para recopilar información sobre los usuarios de la aplicación que ven o interactúan con estos anuncios. La Aplicación no proporciona información de identificación personal a estos terceros.

La Aplicación utiliza la información de identificación personal que usted proporciona a través de la Aplicación para entregar los productos o servicios que ha solicitado; para brindarle atención al cliente; por correo electrónico, correo postal, teléfono y/o dispositivo móvil (incluso a través de servicios de mensajes de texto o "SMS"). ") para comunicarnos con usted sobre productos o servicios de la Aplicación, Socios Prestamistas u otros terceros que puedan ser de su interés; para mostrar anuncios adaptados a sus intereses o antecedentes; para el Uso de la Aplicación para análisis de datos y estadísticos; para hacer cumplir los términos y condiciones de la Aplicación y realizar cualquier otra función que se le describa cuando la Aplicación recopila información.

La Aplicación puede revisar y utilizar su información financiera y su número de seguro social para conectarlo con Socios Prestamistas u otros prestamistas externos con quienes la Aplicación tiene una relación comercial. La aplicación también puede revisar su información financiera y su número de seguro social para verificar su identidad y protegerlo contra posibles transacciones fraudulentas.

La Aplicación puede compartir su Información de Identificación Personal (incluyendo, entre otros, la información de su cuenta financiera y su número de seguro social) con Socios Prestamistas y otros prestamistas externos con quienes la Aplicación tiene una relación comercial. Estos prestamistas externos pueden utilizar su información de identificación personal para ofrecerle un préstamo personal o comunicarse con usted sobre otras ofertas comerciales o de marketing. Además, la Aplicación puede compartir su Información de identificación personal (incluida la información de su cuenta financiera y su número de seguro social) con vendedores externos no afiliados y administradores de listas con quienes la Aplicación tiene una relación comercial.

Los terceros con quienes la Aplicación comparte información de identificación personal pueden tener sus propias políticas que describen cómo usan y divulgan su información. Esas políticas regirán el uso, manejo y divulgación de su información una vez que la Aplicación la haya transferido o compartido con esos terceros como se describe en esta Política o en los Términos y condiciones de la Aplicación.

La Aplicación divulgará su Información de identificación personal en respuesta a una citación o demanda de investigación similar, una orden judicial o una solicitud de cooperación de una agencia de aplicación de la ley u otra agencia gubernamental; para establecer o ejercer nuestros derechos legales; defenderse de reclamaciones legales; o según lo exija la ley. La aplicación divulgará su información de identificación personal cuando considere que la divulgación es necesaria para investigar, prevenir o tomar medidas con respecto a actividades ilegales, sospecha de fraude u otras irregularidades; para proteger y defender los derechos, la propiedad o la seguridad de la empresa matriz de la Aplicación, sus empleados, sus usuarios u otros; o para hacer cumplir los Términos y condiciones u otros acuerdos o políticas.

Además, la Aplicación puede transferir Información de identificación personal a una entidad que adquiera todas o sustancialmente todas las acciones o activos de su empresa matriz.

La Aplicación puede proporcionar enlaces o redirigirlo automáticamente a fuentes de terceros que no operan según esta Política. Por ejemplo, si hace clic en un anuncio presentado en nuestra aplicación, es posible que se le lleve a una fuente que la aplicación no opera ni controla. Debe revisar las declaraciones de privacidad y los términos de uso de todas las fuentes de terceros a las que acceda. Estas fuentes de terceros pueden solicitar y recopilar información sobre usted de forma independiente. En ocasiones, esas fuentes de terceros pueden proporcionar a la Aplicación información sobre su uso de esas fuentes.

Las políticas, el contenido, la información, las promociones, las divulgaciones, las exenciones de responsabilidad y las características de la Aplicación pueden revisarse, modificarse, actualizarse y/o complementarse en cualquier momento y sin previo aviso a la exclusiva y absoluta discreción de la aplicación. Los cambios a esta Política se publicarán en la Aplicación y la Aplicación revisará la fecha de "Efectividad" en la parte superior de esta política. Debe revisar esta Política cada vez que acceda a la Aplicación. Si la Aplicación realiza algún cambio importante en la forma en que recopila, usa y/o comparte información personal que pueda afectarlo, la Aplicación se lo notificará mediante la publicación destacada de los cambios en esta aplicación.

La aplicación no está destinada a ser utilizada por personas menores de 18 años o la mayoría de edad en su jurisdicción. La Aplicación no recopila intencionalmente Información de Identificación Personal de niños menores de 18 años.

La Aplicación ha implementado medidas de seguridad físicas, de procedimiento y tecnológicas para ayudar a prevenir el acceso no autorizado y el uso inadecuado de la información que recopila la Aplicación. Por ejemplo, solo los empleados autorizados y los terceros autorizados pueden acceder a la Información de identificación personal, y pueden hacerlo únicamente para funciones comerciales permitidas. Ninguna aplicación o red de Internet puede ser completamente segura. Aunque la Aplicación toma medidas para proteger su información, no garantiza, y usted no debe esperar, que su información personal, búsquedas u otras comunicaciones permanezcan siempre seguras.

Si desea revisar y/o solicitar cambios en la información de identificación personal que la Aplicación ha recopilado sobre usted a través de su uso, comuníquese con la Aplicación escribiendo o enviando un correo electrónico a las direcciones a continuación.`,
    };
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
}

.imgBox1 {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}

.imgBox2 {
  display: flex;
  justify-content: center;
  max-width: 1400px;
  padding-top: 40px;
  padding-bottom: 20px;
  height: 3700px;
  background-color: #FFFFFF;
  border-radius: 32px;
  margin: -140px auto 150px;
}

.imgBox3 {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}

img {
  display: block;
}

.img1 {
  overflow: hidden;
  width: 100%;
}

.img2,
.img4 {
  overflow: hidden;
}

.img3 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}

.imgItem1 {
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}

.imgItem2 {
  max-width: 100%;
}

.imgItem3 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}

h1 {
  margin-top: 50px;
  margin-left: 490px;
  color: #3F4842;
  font-size: 40px;
  /* border-bottom: 5px solid #04358A; */
  display: inline-block;
}

.textBox {
  margin-top: 50px;
  margin-left: 100px;
  margin-right: 100px;
  font-size: 18px;
  color: #333333;
}

.imgBox2 {
  transform: scale(90%);
  /* transform: scale(80%); */
  margin: -140px auto 100px;
  height: 2700px;
  /* box-shadow: 0 4px 5.3px 2px rgba(0, 0, 0, 0.25); */
  /* border: 2px solid #333333; */
}

.textBox {
  margin-top: 20px;
  margin-left: 50px;
  margin-right: 50px;
}

/* .imgBox2{
  border-radius: 0;
  border-top: 3px solid #7c2af8;
  border-left: 2px solid #e9e9e9;
  border-right: 2px solid #e9e9e9;
  border-bottom: 3px solid #c1c1c1;
} */
.title-line {
  width: 430px;
  /* background: url(./components/img/Group1.png); */
  /* background-repeat: no-repeat; */
  /* background-position: -150px 10px; */
  /* height: 50px; */
  /* border: 1px solid red; */
  border: 5px solid #0BAF60;
  margin: 0 500px;
}

.img1 {
  margin-top: 40px;
  /* padding: 0 200px; */
}
</style>